<script>
    import appConfig from "../../app.config";
    import db from "@/firebase/db";


    export default {
        page: {
            title: "Starter",
            meta: [{
                name: "description",
                content: appConfig.description
            }],
        },
        data() {
            return {
                array: [],
                title: "Starter",
                items: [{
                    text: "Pages",
                    href: "/",
                }, {
                    text: "Starter",
                    active: true,
                }, ],
            };
        },
        components: {

        },
        methods: {



            login() {

                // console.log(auth)




                // const snapshot = await db.collection('test').get()
                // snapshot.docs.map(doc => {
                //     console.log(doc.data())
                // })



            },
            logout() {

            }
        },
    };
</script>

<template>
  <div class="mt-5"></div>
<div class="mt-5" v-for="(item,index) in array">{{item}}</div>
</template>